import React from "react"

import pam from "../images/pam-banner.jpg"
// IMAGEN DEL banner

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import '../components/font-awesome';
// import { Accordion, Card } from "react-bootstrap"
// import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CourseForm from "../components/course-form"

import LogoUniversities from "../images/dobleTitulacion/Logo_Universities.png" 
import Montpellier from "../images/dobleTitulacion/Montpellier.jpg"
import ESIC from "../images/dobleTitulacion/ESIC.png"
import IQS from "../images/dobleTitulacion/IQS.png"
import Laval from "../images/dobleTitulacion/Laval.png"
import VIADRINA from "../images/dobleTitulacion/VIADRINA.jpg"

const webcentrix={
    Level:'', 
    academicUnit: 'ICDA', 
    career: 'programas-internacionales'
}


const MontpellierLink = "https://www.montpellier-bs.com/international/"
const EsicLink = "https://www.esic.edu/"
const IqsLink = "https://www.iqs.edu/es"
const LavalLink = "https://www.ulaval.ca/en"
const ViadrinaLink = "https://www.europa-uni.de/en/studium/informieren-orientieren/index.html"

const logosContent = [
    {
        logo: Montpellier,
        logoLink: MontpellierLink,
        name: "Montpellier Business School - Francia",
        addressedTo: "Estudiantes MBA",
        duration: "1 año + TF (septiembre - julio)",
        title: "DESCM - Diploma de Estudios Superiores de Comercio",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: VIADRINA,
        logoLink: ViadrinaLink,
        name: "European Universität Viadrina Frankfurt (Oder) - Alemania",
        addressedTo: "Estudiantes MBA",
        duration: "1 año + TF (octubre - agosto)",
        title: "Diplom Kaufmann /frau",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: Laval,
        logoLink: LavalLink,
        name: "Université Laval - Canadá",
        addressedTo: "Estudiantes MBA (cursado completo y aprobado sin TF)",
        duration: "1 año + TF (septiembre - julio)",
        title: "MBA Global Business degree",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: IQS,
        logoLink: IqsLink,
        name: "IQS School of Management (Universidad Ramón Llull) - España",
        addressedTo: "Estudiantes MBA",
        duration: "1 año + TF (octubre - junio)",
        title: "Master in Industrial Business Management",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: IQS,
        logoLink: IqsLink,
        name: "IQS School of Management (Universidad Ramón Llull) - España",
        addressedTo: "Estudiantes MBA",
        duration: "1 semestre (febrero - junio)",
        title: "Master in Industrial Business Management",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: IQS,
        logoLink: IqsLink,
        name: "IQS School of Management (Universidad Ramón Llull) - España",
        addressedTo: "Estudiantes EFIN",
        duration: "1 año + TF (octubre - junio)",
        title: "Master in Wealth and Financial Management",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: IQS,
        logoLink: IqsLink,
        name: "IQS School of Management (Universidad Ramón Llull) - España",
        addressedTo: "Graduados/as EFIN",
        duration: "1 semestre + TF",
        title: "Master in Wealth and Financial Management",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: ESIC,
        logoLink: EsicLink,
        name: "ESIC - España",
        addressedTo: "Estudiantes MBA (con cupo mínimo de estudiantes)",
        duration: "Consultar",
        title: "GESCO - Master en Marketing y Gestión Comercial",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: ESIC,
        logoLink: EsicLink,
        name: "ESIC - España",
        addressedTo: "Estudiantes REGULARES, graduados, docentes y otros miembros de la comunidad ICDA. Con cupo mínimo",
        duration: "De 2 a 3 meses - Consultar",
        title: "TOP UP'S o Certificados de especialización",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: ESIC,
        logoLink: EsicLink,
        name: "ESIC - España",
        addressedTo: "Estudiantes REGULARES, graduados, docentes y otros miembros de la comunidad ICDA. Con cupo mínimo",
        duration: "1 o 2 semanas VER ANEXO CONVENIO. Existe la posibilidad de personalizar el BST en términos de duración, temática y servicios prestados",
        title: "Business Study Tour (BST)",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    {
        logo: ESIC,
        logoLink: EsicLink,
        name: "ESIC - España",
        addressedTo: "Estudiantes de carreras de Grado de la UCC o Alumni de ICDA",
        duration: "Aproximadamente 1 año. Consultar",
        title: "Programas Master De Esic",
        moreInfo: "internacional.sai@ucc.edu.ar - (0351) 421 9000 int. 3"
    },
    
]

const internacionalPage = ({ data }) => (
    <Layout>
        <SEO title="Doble Titulación - ICDA" />
        <section className="mb-4">
            {/* Banner and Title */}
            <div className="w-100 d-flex bg-light inst-img mb-4" style={{ backgroundImage: `url(${pam})` }}>
                <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
                    <h2 className="mb-4 fw-bold text-uppercase title">
                        Doble Titulación
                    </h2>
                </div>
            </div>

            {/* Content */}
            <div className="container">
                <div className="row d-flex align-items-center my-5">
                    <div className="col-4 doble-titulacion">
                        <h4 className="font-weight-600 title">
                            CONVENIOS
                        </h4>
                        <h4 className="font-weight-600 title">
                            DE DOBLE
                        </h4>
                        <h4 className="font-weight-600 title">
                            GRADUACIÓN
                        </h4>
                    </div>
                    <div className="col-8">
                        <img src={LogoUniversities} alt="logo-universities"/>
                    </div>
                </div>

                <hr></hr>

                <div className="my-5">
                    {logosContent.map((logoContent, index) => {
                        return (
                        <div className="row d-flex align-items-center">
                            <div className="col-4 d-flex justify-content-end">
                                <a href={logoContent.logoLink} target="_blank" rel="noreferrer">
                                    <img src={logoContent.logo} style={{maxWidth: "210px", maxHeight: "210px"}} alt={`logo-${index}`}/>
                                </a>
                            </div>
                            <div className="col-8">
                                <h4 className="mb-3">{logoContent.name}</h4>
                                <ul>
                                    <li className="mb-1"><span className="font-weight-bold font-italic">Dirigido a:</span> {logoContent.addressedTo}</li>
                                    <li className="mb-1"><span className="font-weight-bold font-italic">Duración:</span> {logoContent.duration}</li>
                                    <li className="mb-1"><span className="font-weight-bold font-italic">Título:</span> {logoContent.title}</li>
                                    <li className="mb-1"><span className="font-weight-bold font-italic">Información en Intercambio UCC:</span> {logoContent.moreInfo}</li>
                                </ul>
                            </div>
                        </div>
                        )
                    })}
                </div>

                {/* Dropdown de Texto */}
                {/* <div className="my-5">
                    <h4 className="font-weight-bold">
                        MODALIDADES
                    </h4>
                    <p>
                        Dependiendo de los requermientos particulares de desarrollo de talento de cada organización, los prorgramas de capacitación a medida pueden llevarse a cabo a través de alguna de las siguientes modalidades:
                    </p>
                    <div className="mb-3 bg-light px-4 py-2">
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                UNIVERSIDAD CORPORATIVA
                            </h6>
                            <p>
                                Se concibe como un proceso de educación continua a través del cual las empresas gestionan el conocimiento, integran, promueven y garantizan una formación estratégica para sus recursos humanos. Cada año esta capacitación se va adaptando a la realidad vigente de la organización que la realiza.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                PROGRAMAS EJECUTIVOS
                            </h6>
                            <p>
                                Propuestas codiseñadas entre el ICDA y la organización, que permiten en poco tiempo desarrollar habilidades y competencias específicas en alguna de las áreas funcionales de la gestión organizacional: Marketing, Finanzas, Recursos Humanos, Logística, Responsabilidad Social, Liderazgo, entre otras
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                CURSOS DE POSGRADO
                            </h6>
                            <p>
                                Son programas de mediana duración para colaboradores que cuenten con formación de grado previa. La certificación del ICDA es acompañada por un legajo UCC.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                ENTRENAMIENTOS INTENSIVOS
                            </h6>
                            <p>
                                Talleres de corta duración concebidos en un ambiente participativo y de comunicación abierta, con dinámicas prácticas para alcanzar los objetivos de forma inmediata, trabajar y resolver situaciones específicas.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                OUTDOORS EXPERIENCIALES
                            </h6>
                            <p>
                                Jornadas de trabajo al aire libre, con actividades lúdicas y de simulación coordinadas por facilitadores especializados en comunicación, trabajo en equipo y liderazgo.
                            </p>
                        </div>
                        <div className="my-2 py-2">
                            <h6 className="text-sec mb-2">
                                JORNADAS ESPECIALIZADAS
                            </h6>
                            <p>
                                Seminarios, conferencias o desayunos de trabajo diseñados para un área o grupo de personas determinado de la organización. Estas actividades están a cargo de reconocidos docentes y consultores con expertise en diferentes temáticas de la realidad actual.
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* video */}
                {/* <div className="container col-12 col-md-9 w-70 h-10 d-flex align-items-center justify-content-between">
                    <div className="embed-responsive embed-responsive-16by9 mb-5 container">
                        <ReactPlayer
                            url={"https://www.youtube.com/embed/RZQhdTTDqOw?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.ucc.edu.ar&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"}
                            playing={true}
                            muted={true}
                            loop={true}
                            className="fembed-responsive-item"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div> */}
                <div className="my-5">
                    <CourseForm webcentrix={webcentrix}/>
                </div>
            </div>
        </section>
    </Layout>
)


export default internacionalPage
